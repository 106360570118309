
































































































































import { ValidationProvider, ValidationObserver } from "vee-validate";
import { Component, Vue, Watch } from "vue-property-decorator";
import Brand from "@/view/layout/brand/Brand.vue";
import OtpInput from "@/view/content/oneTimePassword/OtpInput.vue";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import VueCountdown from "@chenfengyuan/vue-countdown";

import { authModule, errorModule } from "@/store";

//validation rules
import { configure } from "vee-validate";

configure({
  classes: {
    valid: "has-success",
    invalid: "has-error",
  },
});

import { extend } from "vee-validate";
import { required, length } from "vee-validate/dist/rules";
extend("required", required);
extend("length", length);

import { localize } from "vee-validate";
import ru from "vee-validate/dist/locale/ru.json";
localize("ru", ru);

localize({
  en: {
    names: {
      Password: "Password",
    },
  },
  ru: {
    names: {
      Password: "Пароль",
    },
    fields: {
      Password: {
        required: "введите код из смс",
        length: "введите код из смс",
      },
    },
  },
});

@Component({
  components: {
    Brand,
    VueCountdown,
    OtpInput,
    ValidationProvider,
    ValidationObserver,
  },
})
export default class EnterPassword extends Vue {
  password = "";
  counting = true;
  countdown = 60000;

  mounted() {
    if (!this.login) {
      this.$router.push({ name: "login" });
      return;
    }
    (this.$refs.password as OtpInput).focus();
  }

  get login() {
    return authModule.state.phone as string;
  }

  get errors() {
    return errorModule.state.errors;
  }

  get loading() {
    return authModule.state.loading;
  }

  onCountdownEnd() {
    this.counting = false;
    this.countdown = this.countdown * 2;
  }

  canRepeatSendSms() {
    return this.countdown <= 1920000;
  }

  getSms(): void {
    if (!this.canRepeatSendSms) {
      return;
    }
    authModule.actions.getRepeatTempPassword().then(() => {
      this.counting = true;
    });
  }

  sendOneTimePassword(): void {
    authModule.actions.login({ password: this.password }).then((token) => {
      this.$router.push({ name: "home" });
    });
  }

  setPassword(password: string, send = false) {
    this.password = password;
    if (send) {
      this.sendOneTimePassword();
    }
  }

  transformSlotProps(props: { [key: string]: number }) {
    const formattedProps = {} as { [key: string]: string };

    Object.entries(props).forEach(([key, value]) => {
      formattedProps[key] = value < 10 ? `0${value}` : String(value);
    });

    return formattedProps;
  }

  @Watch("errors")
  errorRised() {
    if (this.errors == null || this.errors.every(x => x.Value === '' || x.Value == null)) return;
    Swal.fire({
      title: "Ошибка",
      text: this.errors.join("<br/>"),
      icon: "warning",
      heightAuto: false,
    });
  }
}
