var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-root"},[_c('div',{staticClass:"login login-4 login-signin-on d-flex flex-row-fluid",attrs:{"id":"kt_login"}},[_c('div',{staticClass:"d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat",style:({
        backgroundImage: ("url('" + (require('@/assets/media/bg/bg-3.jpg')) + "')"),
      })},[_c('div',{staticClass:"login-form text-center p-7 position-relative overflow-hidden"},[_c('div',{staticClass:"d-flex flex-center mb-15"},[_c('a',{attrs:{"href":"#"}},[_c('inline-svg',{staticClass:"logo",attrs:{"src":require('@/assets/testbook-logo-beta.svg')}})],1)]),_c('div',{staticClass:"login-signin"},[_c('ValidationObserver',{staticClass:"form",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var handleSubmit = ref.handleSubmit;
      var invalid = ref.invalid;
return [_c('ValidationProvider',{attrs:{"name":"Password","rules":"required|length:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var classes = ref.classes;
      var errors = ref.errors;
return [_c('div',{staticClass:"form-group mb-5",class:classes},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control h-auto form-control-solid py-4 px-8",attrs:{"name":"Password","placeholder":"Пароль","type":"hidden","autocomplete":"off"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('div',{staticClass:"help-block with-errors"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('b-card',[_c('div',{staticClass:"text-center mb-5"},[_vm._v(" Введите одноразовый пароль из СМС ")]),_c('OtpInput',{ref:"password",staticClass:"justify-content-center",attrs:{"isInputNum":true,"separator":'&nbsp;',"numInputs":4,"inputClasses":"form-control h-auto form-control-solid py-4 px-4 mb-10"},on:{"on-change":function($event){return _vm.setPassword($event)},"on-complete":function($event){return _vm.setPassword($event, true)}}}),_c('button',{staticClass:"btn font-weight-bold px-9 py-4 my-3 mx-4",class:{ 'btn-primary': !invalid, 'btn-default': invalid },attrs:{"type":"button","disabled":_vm.loading || invalid},on:{"click":function($event){return handleSubmit(_vm.sendOneTimePassword)}}},[(_vm.loading)?[_c('b-spinner'),_vm._v(" Авторизация... ")]:[_vm._v(" Отправить ")]],2),_c('div',{staticClass:"mt-5"},[_c('span',{staticClass:"text-center"},[_vm._v("Код отправлен на "+_vm._s(_vm.login))]),_c('br'),_c('br'),(_vm.counting)?_c('span',{staticClass:"text-center"},[_vm._v(" Запросить повторно можно через "),_c('VueCountdown',{attrs:{"time":_vm.countdown,"transform":_vm.transformSlotProps},on:{"end":_vm.onCountdownEnd},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var minutes = ref.minutes;
      var seconds = ref.seconds;
return [_vm._v(_vm._s(minutes)+":"+_vm._s(seconds))]}}],null,true)})],1):(_vm.canRepeatSendSms)?_c('span',{staticClass:"btn btn-link",on:{"click":function($event){return _vm.getSms()}}},[_c('u',[_vm._v("Запросить повторно")])]):_vm._e()])],1)]}}])})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }